export const pricingSection = {
    title: "Kenapa Ledgerowl",
    main_title: "Murah, Mudah, Cepat",
    description: "Solusi keuangan bisnis yang menyesuaikan kebutuhan Anda dengan hasil yang akurat.",
    list: [
        {
            image: "cash",
            title: "Hemat hingga 250%",
            description: "Hemat gaji akuntan IDR 5 juta/bulan, tunjangan dan biaya lainnya mulai dari Rp IDR 1.900.000/bulan."
        },
        {
            image: "bolt",
            title: "Upload, terima laporan",
            description: "Hanya perlu upload dokumen, kemudian terima beres laporan keuangan dengan praktis."
        },
        {
            image: "clock",
            title: "Efisiensi pekerjaan",
            description: "Kurangi waktu untuk pekerjaan berulang dengan bantuan staf terlatih & teknologi kami."
        },
    ],
    detail: {
        title: "Harga layanan",
        description: "Personalisasi layanan dan harga sesuai kebutuhan bisnis.",
        list_title: "Keuntungan",
        pricing: {
            title: "Mulai dari",
            price: "IDR 1.900.000",
            type_price: "per bulan",
            button_text: "Konsultasi Gratis"
        },
        list: [
            {
                description : "Layanan konsultasi 1:1",
            },
            {
                description : "Dukungan 7 hari penuh",
            },
            {
                description : "Keamanan dokumen & data",
            },
            {
                description : "Pekerjaan berbasis teknologi",
            },
        ]
    }
}